/* .map-wrapper {
  position: relative;
  width: 100%;
  height: 100%;
} */


.map-container {
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
}

/* .mapDiv::-webkit-scrollbar {
  display: none;
} */

.flightlabel {
    color: lightgreen;
    font-size: small;
    cursor:grab;
}

/* Marker tweaks */
.mapboxgl-popup-close-button {
    display: none;
}
.wxpopup {
  text-align: center;
  font-size: small;

}

.wxpopup h5 {
  background-color:#616c7e52 ;
  text-align: center;
  padding: .25rem;
  
}

.mapboxgl-popup-content {
  background: #000000d5;
  border-radius: 5px;
  max-width:240px;
  
}

.mapboxgl-popup-tip {
  background-color: transparent;
}

.popup h5 {
    background-color:#616c7e52 ;
    text-align: center;
    padding: .25rem;
    
}

.tablevalue {
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
    text-align: right;
    padding-left: 10px; 
    font-size: 12pt;
}

.tableitem {
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
    padding-right: 10px;
    font-size: 12pt;
    font-weight: bold;
}

.mapboxgl-marker.marker {
  border: none;
  cursor:help;
}