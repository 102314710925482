.layerToggle {
    filter: invert(1);
    position: absolute;
    right: 30px;
    top:60px;
    background-color: black;
    padding: 5px;
    border-radius: 5px;
    cursor: pointer;

    
}