.acStatusCard {
    background-color: rgb(67, 67, 67);
    border-radius: 15px;
    border-color: rgb(56, 56, 56);
    transition: all .2s ease-in-out;
    
}

.acMaintStatusCardSection {
border-right: 2px solid rgb(46, 46, 46);
}
    

.acMaintStatusCard {
    background-color: rgb(67, 67, 67);
    border-radius: 15px;
    border-color: rgb(56, 56, 56);
    transition: all .2s ease-in-out;
}

.acMaintStatusCard:hover {
    transform: scale(1.01);
    cursor: pointer;
}