body {
    margin: 0;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto',
        'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans',
        'Helvetica Neue', sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    background-color: #3b3f41;
    color: white;
    /* font-size: larger; */
    padding-top: 0px;
    margin-bottom: 60px;
    /* padding-bottom: 60px; */
}
.containerMain {
    padding: 0px;
    /* padding-bottom: 80 px; */

}
.brand-logo {
    width: 20%;
}

.brand-logo > img {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
}

.departuresTable img {
    height: auto;
    max-width: 50%;
    padding: 0px;
}

table {
    cursor: pointer;
    width: 100vw;
    max-width: 100vw;
}
.fidTable{
    
    
  }

.modalHeader {
    padding-top: 0 px;
    padding-bottom: 10px;
    font-size: 32pt;

    margin-bottom: 0 px;

    color: lightgray;
    background-color: rgb(35, 123, 163);
}

.modalStyle {
    width: 90vw;    /* Occupy the 90% of the screen width */
    max-width: 90vw;
}

.refImageModal {
    background-color: blue
}

.gridRow h2 {
    padding-left: 10px;
    padding-right: 10px;
    padding-top: 20px;
    padding-bottom: 20px;
}

.gridCol {
    padding-top: 20px;
    padding-bottom: 20px;
    margin-left: 20px;
    margin-right: 20px;
}

#collapsible-nav-dropdown {
    color: rgb(225, 225, 225)
}

.dropdown-menu {
    background-color:#3b3f41;
    padding: 5px;
   
}
.navDropdown {
    padding-top: 0px;
    padding-bottom: 0px;
    color:rgb(172, 172, 172)
}
.profileNavDropdown {
    padding-top: 0px;
    padding-bottom: 0px;
    margin-left: 20px;
    color:rgb(172, 172, 172)
}
.navbar-toggler-icon {
    width: 1.0em;
    height: 1.0em;
}

.dropDownLinks {
    color: rgb(225, 225, 225);
    display: block;
    margin-top: 10px;
    margin-bottom: 10px;
    padding: 10px;
    text-decoration: none;
    border-radius: 5px;
}
.dropDownLinks {
    color: rgb(225, 225, 225);
    display: block;
    margin-top: 10px;
    margin-bottom: 10px;
    padding: 10px;
    text-decoration: none;
    border-radius: 5px;
    
}
.dropDownLinks.active {
    color: rgb(225, 225, 225);
    background-color: rgb(79, 108, 121);
    display: block;
    margin-top: 10px;
    margin-bottom: 10px;
    padding: 10px;
    text-decoration: none;
    border-radius: 5px;
}

.bottomNavLinks {
    color: rgb(225, 225, 225);

}

.bottomNavLinks.active {
    color:rgb(79, 108, 121);
}

.navBarLinks {
    color: white;
    margin: 2px;
    padding: 5px;
    text-decoration: none;
}

.navBarLinks.active {
    color: rgb(255, 255, 255);
    padding: 5px;
    text-decoration: none;
    background-color: rgb(79, 108, 121);
    border-radius: 5px;
}

a:hover.navBarLinks {
    text-decoration: none;
    color: rgb(172, 172, 172);
}

.loadingBar h6 {
    text-align: center;
    padding: 5px;
}

.dash-section {
    /* background-color: #343a40; */
    
    margin-top: 1rem;
    margin-bottom: 1rem;
}

.lineChart {
    background-color: #343a40;
}

.datePicker {
    padding: 5px;
    text-aligh: center;
}

.badge-vfr {
    color: #FFFFFF;
    background-color: #31e32b;
    margin-top: 5px;
}

.badge-mvfr {
    color: #ffffff;
    background-color: #0026ff;
}

.badge-ifr {
    color: #ffffff;
    background-color: #ff0000;
}

.badge-lifr {
    color: #ffffff;
    background-color: #ea00ff;
}
